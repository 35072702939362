<template>
  <div :style="backgroundStyle" class="auth-container">
    <div class="overlay"></div>

    <!--     <BRow>
          <BCol>
           <h1>
              ТТН Генератор CRM для інтернет магазинів, повна автоматиація усіх процесів
            </h1>
            <h5>
              Швидка обробка замовлень, 120 замовлень на день одним співробітником не межа.
              Массове створення ТТН Нова Пошта, Укрпошта.
              Массове створення, друк, розсилка чеків.
              Массова рассилка ТТН в СМС.
              Легкий контроль одержання післяплат та повернень посилок
            </h5>
            <div class="content">
              <BButton size="lg" variant="warning" @click="loginWithGoogle">Увійти через Google</BButton>
            </div>
          </BCol>
          <BCol>
            Відео
          </BCol>

        </BRow>
         -->


    <div class="content">
      <BButton size="lg" variant="warning" @click="loginWithGoogle">Увійти через Google</BButton>
    </div>
  </div>
</template>

<script>
import {ref, onMounted} from "vue";
import {useStore} from "vuex";
import {BButton} from "bootstrap-vue-next";

export default {
  setup() {
    const store = useStore();
    const backgroundStyle = ref({});

    const loadBackgroundImage = () => {
      const image = new Image();
      image.src = new URL('@/assets/img/crm-auth.jpg', import.meta.url).href;
      image.onload = () => {
        backgroundStyle.value = {
          backgroundImage: `url(${image.src})`,
          backgroundSize: "cover",       // Масштабування зображення
          backgroundPosition: "top left", // Початкова точка у верхньому лівому куті
          backgroundRepeat: "no-repeat", // Вимкнення повторення
          height: "100vh",              // Висота на весь екран
          width: "100%",                // Ширина на весь контейнер
        };
      };
    };

    onMounted(() => {
      loadBackgroundImage();
    });

    const loginWithGoogle = () => {
      store.commit("authModule/loginWithGoogle");
    };

    return {
      loginWithGoogle,
      backgroundStyle,
    };
  },
  components: {
    BButton,
  },
};
</script>

<style scoped>
.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Позиціонування контейнера */
  overflow: hidden; /* Приховує частини зображення за межами контейнера */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Напівпрозорий чорний шар */
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2; /* Контент поверх шару */
  text-align: center;
  color: white;
}
</style>
