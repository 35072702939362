import axios from 'axios';
import store from '../store/store'

/*const apiCall = async (method, url1, payload = null, responseType = 'json') => {
    const backUrl = process.env.VUE_APP_LARAVEL_APP_URL;
     //console.log('backUrl', backUrl);
    let url = backUrl + url1;
    const token = store.getters['authModule/token'];
    const uuid = store.getters['authModule/uuid'];

   console.log(' uuid ', uuid);

    try {
        const headers = {
            'X-Socket-ID': store.getters['authModule/getSocket'],
            'Accept': responseType === 'json' ? 'application/json' : 'application/pdf',
            'Authorization': token ? `Bearer ${token}` : ''
        };
        const options = {method, url, headers};
        if (payload) {
            options.data = payload;
        }
        if (responseType !== 'json') {
            options.responseType = responseType;
        }

       // console.log('options full', options);
        const response = await axios(options);
        //console.log('api query response', response);
        if(response.data.message === 'Unauthenticated.'){
           // console.log('join in logout');
            await store.commit('authModule/logout', null, {root: true})
        } else {

        return responseType === 'json' ? response.data : response;
        }
    }
    catch (error) {
        if (error.response) {


            if(error.response.data.message === 'Unauthenticated.'){
                await store.commit('authModule/logout', null, {root: true})
                return;
            }

            // Here you can handle specific status codes
          //  console.log('error.response', error.response);
            switch (error.response.status) {
                case 400:
                 //   console.error('Bad Request:', error.response.data);
                    // You can return or show an error message to the user
                    break;
                case 409:
                    console.error('Conflict:', error.response.data);
                    // Handle conflict
                    break;
                default:
                    console.error('An unexpected error occurred:', error.response.data);
                // Handle other cases
            }
        } else {
            console.error('API Call Error:', error);
        }
        return error.response ? error.response.data : 'An unexpected error occurred';
    }
};*/


const apiCall = async (method, url1, payload = null, responseType = 'json') => {
    const backUrl = process.env.VUE_APP_LARAVEL_APP_URL;
    let url = backUrl + url1;
    const token = store.getters['authModule/token'];
    let uuid = store.getters['authModule/uuid'];


    if (!uuid) {
       // console.warn('UUID is not defined in store, falling back to localStorage');
        const senderSettings = JSON.parse(localStorage.getItem('senderSettings'));
       // console.log('senderSettings:', senderSettings);
        uuid = senderSettings?.uuid;
    }

    if (!uuid) {
       // console.error('UUID is not available in both store and localStorage');
    }


    try {
        const headers = {
            'X-Socket-ID': store.getters['authModule/getSocket'],
            'Accept': responseType === 'json' ? 'application/json' : 'application/pdf',
            'Authorization': token ? `Bearer ${token}` : '',
            'X-GUID': uuid
        };



        const options = { method, url, headers };

        if (payload) {
            options.data = payload;
        }

        if (responseType !== 'json') {
            options.responseType = responseType;
        }

        const response = await axios(options);

        if (response.data.message === 'Unauthenticated.') {
            await store.commit('authModule/logout', null, { root: true });
        } else {
            return responseType === 'json' ? response.data : response;
        }
    } catch (error) {
        if (error.response) {
            if (error.response.data.message === 'Unauthenticated.') {
                await store.commit('authModule/logout', null, { root: true });
                return;
            }

            // Handle specific status codes
            switch (error.response.status) {
                case 400:
                    console.error('Bad Request:', error.response.data);
                    break;
                case 409:
                    console.error('Conflict:', error.response.data);
                    break;
                default:
                    console.error('An unexpected error occurred:', error.response.data);
            }
        } else {
            console.error('API Call Error:', error);
        }
        return error.response ? error.response.data : 'An unexpected error occurred';
    }
};






export default apiCall;
