<template>
  <BModal
      v-model="modalState.localModalStatus"
      no-close-on-backdrop
      size="lg"
      :title="modalState.title"
      id="modal-scrollable"
      hide-footer

      @close="closeModal"
      scrollable
      lazy
  >
    <div v-if="spinner" class="d-flex justify-content-center">
      <BSpinner/>
    </div>
    <div v-if="modalState.type === 'controlByDate'">

      <div>
        <!-- Умовне рендеринг для відображення тільки першого рядка або повного тексту -->
        <div v-if="!isExpanded">
          {{ truncatedText }}
          <span @click="isExpanded = true" class="text-primary" style="cursor: pointer;">читати повністю</span>
        </div>
        <div v-else>
          {{ fullText }}
          <span @click="isExpanded = false" class="text-primary" style="cursor: pointer;">згорнути</span>
        </div>
      </div>

      <BInputGroup size="sm" prepend="Дата одержання переказів">
        <!--        <BFormInput v-model="currentDate" type="date"></BFormInput>-->

        <!-- Використання append-to-body -->
        <BDropdown class="position-static"
                   menu-class="dropdown-menu-unrestricted"
                   boundary="viewport"
                   container="body"
                   :text="currentDate"
        >
          <BDropdownItem v-for="(color, dateValue) in daysWithPayments"
                         :key="dateValue"
                         @click="loadPaymentsByDate(dateValue)"
                         :variant="color"
                         class="position-static">
            {{ dateValue }}
          </BDropdownItem>
        </BDropdown>


      </BInputGroup>
    </div>
    <div v-else-if="respData.active !== true
     && modalState.type === 'changeStatus'">
      <div v-if="spinerActive !== true">
        <p class="size-xs">Оберіть тип данних по якому треба зробити масову зміну статусів замовлень:
          по номерам ТТН чи по Номерам замовлень</p>
        <BInputGroup class="m-2">
          <BDropdown
              :text="selectedNumberType.name"
              variant="btn btn-outline-secondary btn-sm me-2"
              boundary="viewport"
              container="body"
          >
            <BDropdownItem v-for="item in numberTypes"
                           :key="item.type"
                           @click="selectNumberType(item)"
            >{{ item.name }}
            </BDropdownItem>
          </BDropdown>

          <BDropdown v-if="selectedNumberType.type === 'ttn'"
                     :text="selectedDeliveryType.name"
                     boundary="viewport"
                     container="body"
                     variant="btn btn-outline-secondary btn-sm me-2">
            <BDropdownItem v-for="item in deliveryTypes"
                           :key="item.type"
                           @click="selectDeliveryType(item)"
            >{{ item.name }}
            </BDropdownItem>
          </BDropdown>

          <!--          <BDropdown v-if="selectedNumberType.type === 'orderNumber'"
                               :text="selectedSourceName.name"
                               variant="btn btn-outline-secondary btn-sm me-2">
                      <BDropdownItem v-for="item in SourceNames"
                                     :key="item.type"
                                     @click="selectSourceName(item)"
                      >{{ item.name }}
                      </BDropdownItem>
                    </BDropdown>-->
        </BInputGroup>
        <BFormTextarea
            id="textarea"
            v-model="ttnsOrOrdersBodyValue"
            placeholder="Ви можете вставити неформатований список ТТН безспосередньо з листа від нової пошти про перекази контроль оплат на ваш рахунок"
            rows="3"
            max-rows="6"
        />
        <BInputGroup>
          <BDropdown
              :text="selectedOrderStatus.name"
              variant="btn btn-outline-secondary btn-sm me-2"
              boundary="viewport"
              container="body"
          >
            <BDropdownItem v-for="status in orderStatuses"
                           :key="status.id"

                           @click="selectOrderStatus(status)"
            >{{ status.name }}

            </BDropdownItem>
          </BDropdown>
          <!--        <BButton variant="secondary" class="mr-2" @click="cancel">{{ cancelButtonValue }}</BButton>-->
          <BButton variant="primary" size="sm" @click="extractNumbers">Застосувати</BButton>
        </BInputGroup>
      </div>
      <div v-else class="d-flex justify-content-center">
        <BSpinner label="Spinning" variant="primary"/>
      </div>
    </div>
    <template v-if="respData.active === true">
      <!--        <div v-if="Object.keys(respData.successTtns).length > 0">
                <p class="size-xs">За номерами ТТН:</p>
                <BRow >
                  <BCol>Номер ТТН</BCol>
                  <BCol>Післяплата</BCol>
                </BRow>
                <BRow v-for="(ttn, index) in Object.values(respData.successTtns)" :key="ttn.ttns">
                  <BCol>{{ ttn.ttns }}</BCol>
                  <BCol>{{ ttn.after_payment_amount }}</BCol>
                </BRow>
                <BRow>
                  <BCol> {{ respData.successTtns.length }}</BCol>
                  <BCol> {{ amount }} </BCol>
                </BRow>
              </div>-->
      <template>


        <div v-if="Object.keys(respData.successTtns).length > 0" class="table-responsive ">
          <!--          <p class="size-xs">За номерами ТТН:</p>-->
          <template>
            <div class=" table-bordered margin-padding-edit">
              <template v-if="modalState.type === 'changeStatus'" class="size-xs">У статус "Виконтані" перенесено:
              </template>
              <BInputGroup size="sm" prepend="Пошук">
                <BFormInput
                    v-model="searchTerm"
                    placeholder="пошук по номеру"
                />
              </BInputGroup>
              <template>Якщо сумма не співпадає, натисніть "Див" і приберіть прапорець з відповідних замовлень
              </template>
              <div class="table-header">
                <div class="row">
                  <!--                  <div class="col-1"></div>-->
                  <div class="col-5">Контактна особа</div>
                  <div class="col-3">ТТН</div>
                  <div class="col-2">Сума</div>
                  <div class="col-2">Статус</div>
                </div>
              </div>
<!--
              <div class="table-body" v-for="(ttns, contactPerson) in respData.successTtns" :key="contactPerson">
                <div class="row fw-600">
                  <div class="col-5">
                    <BButton variant="outline-secondary"
                             size="sm"
                             @click="toggleDetails(contactPerson)">Див
                    </BButton>
                    {{ contactPerson }}
                  </div>
                  &lt;!&ndash;                  <div class="col-3">{{ ttns.length }} шт.</div>&ndash;&gt;
                  <div class="col-3">{{ filteredTtnsLength(ttns) }} шт.</div>
                  <div class="col-2">{{ calculateTotal(ttns) }} грн</div>
                  <div class="col-2">  {{ getGlobalPaymentStatus(ttns) }}  </div>
                </div>
                <template v-if="showDetails[contactPerson]" class="details">
                  <RecycleScroller
                      class="div-table-body scroller"
                      :items="filterTtns(ttns)"
                      :key-field="'ttns'"

                      :item-size="25"
                  >
                    <template v-slot="{ item }">
                      <div class="row" :key="item.ttns">
                        <div class="col-4">{{ paymentTypes[item.paymentType]?.name }}</div>
                        <div class="col-1 d-flex justify-content-end">
                          <BFormCheckbox
                              v-model="selectedTtns"
                              :key="item.orderId"
                              :value="item.orderId">
                          </BFormCheckbox>
                        </div>
                        <div class="col-3">{{ item.ttns }}</div>
                        <div class="col-2">{{ item.after_payment_amount }}</div>
                        &lt;!&ndash;                          <div class="col"> {{ currentOrderStatus(item) }} </div>&ndash;&gt;
                        <div class="col-2"> {{ getOrderStatus(item) }}</div>
                      </div>
                    </template>
                  </RecycleScroller>

                </template>

              </div>
-->

<!--    попередній код вище-->
              <div class="table-body" v-for="(ttns, contactPerson) in respData.successTtns" :key="contactPerson">

                <div class="row fw-600">
                  <div class="col-5">
                    <BButton variant="outline-secondary"
                             size="sm"
                             @click="toggleDetails(contactPerson)">Див
                    </BButton>
                    {{ contactPerson }}
                  </div>
                  <!--                  <div class="col-3">{{ ttns.length }} шт.</div>-->
                  <div class="col-3">{{ filteredTtnsLength(ttns) }} шт.</div>
                  <div class="col-2">{{ calculateTotal(ttns) }} грн</div>
                  <div class="col-2">  {{ getGlobalPaymentStatus(ttns) }}  </div>
                </div>
                <template v-if="showDetails[contactPerson]" class="details">
                  <RecycleScroller
                      class="div-table-body scroller"
                      :items="filterTtns(ttns)"
                      :key-field="'ttns'"

                      :item-size="25"
                  >
                    <template v-slot="{ item }">
                      <div class="row" :key="item.ttns">
                        <div class="col-4">{{ paymentTypes[item.paymentType]?.name }}</div>
                        <div class="col-1 d-flex justify-content-end">
                          <BFormCheckbox
                              v-model="selectedTtns"
                              :key="item.orderId"
                              :value="item.orderId">
                          </BFormCheckbox>
                        </div>
                        <div class="col-3">{{ item.ttns }}</div>
                        <div class="col-2">{{ item.after_payment_amount }}</div>
                        <div class="col-2"> {{ getOrderStatus(item) }}</div>
                      </div>
                    </template>
                  </RecycleScroller>
                </template>
                <div  class="d-flex justify-content-end">
                  <BButton class="text-end"
                           variant="primary"
                           size="sm"
                           @click="revokeOrderStatusChanging(ttns)">
                    Підтвердити
                  </BButton>
                </div>
              </div>
            </div>
          </template>


        </div>
      </template>
      <!--      <div v-if="unselectedOrderIds.length > 0">-->

      <div v-if="respData.errorTtns.length > 0">
        <p class="size-xs">Статуси не були змінені для ТТН:</p>
        {{ respData.errorTtns.join(', ') }}
      </div>
    </template>

<!--    <template #footer>

      <div v-if="notConfirmedExists">
        <div>

          <b>При натисканні "Підтвердити", по відміченним ТТН поставиться відмітка "Підтвердженно отримання наложки",
            а не відміченні будуть перенесені у статус "Зберігання/відмови" з поміткою "Наложка не одержана"
          </b>
        </div>
        <div class="d-flex justify-content-end">
          <BButton class="text-end"
                   variant="primary"
                   size="sm"
                   @click="revokeOrderStatusChanging()">
            Підтвердити
          </BButton>
        </div>
      </div>
    </template>-->

  </BModal>
</template>

<script>

import {
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BModal,
  BSpinner
} from 'bootstrap-vue-next';
import {computed, onMounted, reactive, ref, toRefs, watch} from "vue";
import {useStore} from "vuex";
import {RecycleScroller} from 'vue-virtual-scroller'
import {getDaysWIthPayments, revokeReceivedPayment} from "@/axios/settignsRequests";


export default {
  name: "changeOrderStatusysByNumberModal",
  /* props: {
     modalStatus: Boolean,
     modalTitle: {
       type: String,
       default: 'Масова зміна статусу замовленнь по номерам ТТН або номерам замовлень'
     },
   },*/

  setup() {
    /*    const modalState = ref(props.modalStatus);*/
    const store = useStore();
    const ttnsOrOrdersBodyValue = ref('');

    const modalState = computed(() => {
      return store.getters['changeOrderStatusByTtn/getModalStatus'];
    });

    /*    watch(() => props.modalStatus, (value) => {
          modalState.value = value;
        });*/

    const paymentsByDate = ref(false);

    const showPaymentsByDate = () => {
      paymentsByDate.value = !paymentsByDate.value;
      modalTitle.value = 'Контроль одержання грошових переказів та онлайноплат'
    };

    const daysWithPayments = ref({});


    async function getDays() {
      getDaysWIthPayments().then((response) => {
        daysWithPayments.value = response.data;
       // console.log('dates', daysWithPayments.value)
      }, (error) => {
        console.log('get days error', error);
      });
    }

    const watchArrayLength = () => {
      watch(
          () => selectedTtns.value.length,
          (newLength, oldLength) => {
            if (newLength !== oldLength) {
            //  console.log('length changed');
              notConfirmedExists.value = true;
            }
          }
      );
    };


    onMounted(() => {

      getDays();
    });


    const getFormattedTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = (`0${today.getMonth() + 1}`).slice(-2); // Add leading zero
      const day = (`0${today.getDate()}`).slice(-2); // Add leading zero
      return `${year}-${month}-${day}`;
    };

    // Reactive property for the input value
    // const currentDate = ref(getFormattedTodayDate());
    const currentDateText = 'Оберіть дату'
    const currentDate = ref(currentDateText);


    const paymentTypes = store.getters['order/getPaymentTypes'];

//// Number TYpes
    const numberTypes = [
      {type: 'ttn', name: 'Номери ТТН'},
      {type: 'orderNumber', name: 'Номери замовлень'},
    ];

    const selectedNumberType = ref({});
    selectedNumberType.value = numberTypes[0];
    const selectNumberType = (status) => {
      selectedNumberType.value = status;
    };

/// Delivery types
    const deliveryTypes = [
      {type: 'np', name: 'ТТН Нової пошти'},
      {type: 'up', name: 'ТТН Укрпошти'},
      {type: 'roz', name: 'ТТН Розетка делівері'},
    ];

    const selectedDeliveryType = ref({});

    selectedDeliveryType.value = deliveryTypes[0];

    const selectDeliveryType = (delName) => {
      selectedDeliveryType.value = delName;
    };

    /// Source Names

    const SourceNames = [
      {type: 'rozetka', name: 'Розетка'},
      {type: 'prom', name: 'Prom'},
      {type: 'crm', name: 'CRM'},
    ];

    const selectedSourceName = ref({});

    selectedSourceName.value = SourceNames[0];

    const selectSourceName = (sourceName) => {
      selectedSourceName.value = sourceName;
    };

    /// Order Statuses

    const orderStatuses = store.getters['order/getOrderStatuses'];

    let selectedOrderStatus = ref({});
    selectedOrderStatus.value = orderStatuses[7];

    const selectOrderStatus = (value) => {
     // console.log('select status', value);
      selectedOrderStatus.value = value;
    };

    const spinerActive = ref(false);
    const selectedTtns = ref([]);

    const showDetails = reactive({});
    const toggleDetails = (contactPerson) => {
      showDetails[contactPerson] = !showDetails[contactPerson];
    };

    // selectedTtns.value
    /*    const calculateTotal = (ttns) => {
          return ttns.reduce((total, ttn) => total + parseFloat(ttn.after_payment_amount), 0);
        };*/


    const calculateTotal = (ttns) => {
      // Фільтруємо тільки ті ttns, які є в selectedTtns.value
      const filteredTtns = ttns.filter(ttn => selectedTtns.value.includes(ttn.orderId));

      // Рахуємо загальну суму тільки для відфільтрованих ttns
      return filteredTtns.reduce((total, ttn) => total + parseFloat(ttn.after_payment_amount), 0);
    };

    const filteredTtnsLength = (ttns) => {
      // Фільтруємо ttns, залишаючи лише ті, де orderId є в selectedTtns.value
      const filteredTtns = ttns.filter(ttn => selectedTtns.value.includes(ttn.orderId));
      // Повертаємо довжину відфільтрованого масиву
      return filteredTtns.length;
    };

/*    const getGlobalPaymentStatus = (ttns) => {
      const confirmedCount = ttns.filter(ttn => ttn.payment_confirmed === "confirmed").length;

      if (confirmedCount === ttns.length) {
        return "Підтверджено";
      } else if (confirmedCount > 0) {
        // Декілька значень "confirmed"
        return "Част. підтв";
      } else {
        // Жодного значення "confirmed"
        console.log('notConfirmedExists', notConfirmedExists.value)
       notConfirmedExists.value = true;
        return "Непідтвердж.";
      }
    };*/

    const getGlobalPaymentStatus = (ttns) => {
      return computed(() => {
        const confirmedCount = ttns.filter(ttn => ttn.payment_confirmed === "confirmed").length;
        if (confirmedCount === ttns.length) {
          return "Підтверджено";
        } else if (confirmedCount > 0) {
          return "Част. підтв";
        } else {
          return "Непідтвердж.";
        }
      });
    };




    let respData = reactive({
      'active': false,
      'successTtns': {},
      'successTtnsArray': [],
      'errorTtns': [],
    })

    /*    respData.successTtns = {
          "Цимбал Вячеслав Ігорович": [
            {
              "orderId": 15455,
              "senderContactPerson": "Цимбал Вячеслав Ігорович",
              "ttns": 20450857028685,
              "after_payment_amount": 234,
              "newOrderStatusId": 7
            },
            {
              "orderId": 151555,
              "senderContactPerson": "Цимбал Вячеслав Ігорович",
              "ttns": 20450857274860,
              "after_payment_amount": 0,
              "newOrderStatusId": 7
            },
            {
              "orderId": 155555,
              "senderContactPerson": "Цимбал Вячеслав Ігорович",
              "ttns": 20450857479218,
              "after_payment_amount": 234,
              "newOrderStatusId": 7
            }
          ],
          "ілларіон": [
            {
              "orderId": 155555,
              "senderContactPerson": "Цимбал Вячеслав Ігорович",
              "ttns": 20452857028685,
              "after_payment_amount": 234,
              "newOrderStatusId": 7
            },
            {
              "orderId": 155515,
              "senderContactPerson": "Цимбал Вячеслав Ігорович",
              "ttns": 20450867479218,
              "after_payment_amount": 1234,
              "newOrderStatusId": 7
            }
          ]
        };

        respData.successTtnsArray = [
          15455,
          151555,
          155555,
          155555,
          155515,
        ];

        selectedTtns.value = [
          15455,
          151555,
          155555,
          155555,
          155515,
        ];*/

    /*
    ttns = [
    {
        "orderId": 15455,
        "senderContactPerson": "Цимбал Вячеслав Ігорович",
        "ttns": 20450857028685,
        "after_payment_amount": 234,
        "newOrderStatusId": 7
    },
    {
        "orderId": 151555,
        "senderContactPerson": "Цимбал Вячеслав Ігорович",
        "ttns": 20450857274860,
        "after_payment_amount": 0,
        "newOrderStatusId": 7
    },
    {
        "orderId": 155555,
        "senderContactPerson": "Цимбал Вячеслав Ігорович",
        "ttns": 20450857479218,
        "after_payment_amount": 234,
        "newOrderStatusId": 7
    }
]
     */

    const searchTerm = ref('');

    const filterTtns = (ttns) => {
      if (!searchTerm.value) {
        return ttns;
      }
      // Convert both ttn.ttns and searchTerm.value to string before comparison
      return ttns.filter(ttn => ttn.ttns.toString().includes(searchTerm.value.toString()));
    };


    const paymentStatuses = {
      'confirmed': 'Підтверджено',
      'revoked': 'Неодержано',
      'null': 'непідтвердж.'
    }
    // payment_confirmed

    const notConfirmedExists = ref(false);

    const getOrderStatus = (ttn) => {
      if (!selectedTtns.value.includes(ttn.orderId)) {
        return 'Неодержано';
      /*} else if (ttn.payment_confirmed !== 'null') {*/
      } else {
        if(ttn.payment_confirmed === 'confirmed') {
        //  console.log('payment_confirmed === \'confirmed')
          //notConfirmedExists.value = false;
        }
        return 'Підтверджено';
      }
    };






    /*    const amount = computed(() => {
          let amount = 0;
          Object.values(respData.successTtns).forEach((ttn) => {
            amount += ttn.after_payment_amount;
          });
          return amount;
        });*/


    /*const unselectedOrderIds = computed(() => {
      return respData.successTtnsArray
          .filter(ttn => !selectedTtns.value.includes(ttn));
    });*/

    //const unselectedOrderIds = ref([]);// тичасово

    const revokeOrderStatusChanging = async (ttns) => {

    //  console.log('ttns22', ttns)

    //  console.log('currentDate.value', currentDate.value); // 'Оберіть дату'
    //  console.log('currentDateText', currentDateText); // 'Оберіть дату'


      function selectedTtnsBySource() {
        const selected = ttns.filter(ttn => selectedTtns.value.includes(ttn.orderId));
        const unselected = ttns.filter(ttn => !selectedTtns.value.includes(ttn.orderId));

        return {
          selected: selected.map(ttn => ttn.orderId),   // Значення, які є у selectedTtns
          unselected: unselected.map(ttn => ttn.orderId) // Значення, яких немає у selectedTtns
        };
      }

      const ttnsBySource = selectedTtnsBySource();

   //   console.log('selected and unseletect ttns', ttnsBySource);

      if (currentDate.value === currentDateText){
        const newDate = new Date();
        currentDate.value = newDate.toISOString().split('T')[0];
      }

      let requestOrderIds = {
        // confirmed_orders_ids
        // revoked_orders_ids
        'date': currentDate.value ,
        'revoked_orders_ids': ttnsBySource.unselected,
        'confirmed_orders_ids': ttnsBySource.selected,
      }
      spinner.value = true;
      try {
     //   console.log('requestOrderIds', requestOrderIds);
      //  console.log('selectedTtns.value', selectedTtns.value);
        await revokeReceivedPayment(requestOrderIds).then((response) => {
      //    console.log('revokeReceivedPayment', response);
          if(response.status === 'success') {
            store.dispatch('alert/triggerAlert', {
              show: 3000,
              variant: 'success',
              message: 'Статуси змінено'
            }, {root: true});
            notConfirmedExists.value = false;

           fillSuccessTtns(response.data);

            const orderIds = response.data.successedOrdersIda;
                if(orderIds.length > 0) {
           //   console.log('join in not empty orders ids', orderIds)
              const activeTabs = [4,3];
              // foreach activeTabs
              activeTabs.forEach(activeTab => {
                deleteOrders(orderIds, activeTab);
              });
            }
          }
        });
      } catch (error) {
      //  console.log('помилка зміни статусів', error);
        throw error;  // To propagate the error to the caller.
      }
      spinner.value = false;
    };

    function deleteOrders(orderIds, activeTab) {
      store.commit('order/deleteOrder', {
        crmOrderId: orderIds,
        orderStatusId: activeTab
      });
    }

    const closeModal = () => {
      resetChangeOrderStatusesModal();
      store.commit('changeOrderStatusByTtn/closeModal')
    };


    function resetChangeOrderStatusesModal(){
      respData.active = false;
      respData.successTtns = {};
      respData.successTtnsArray = [];
      respData.errorTtns = [];
      ttnsOrOrdersBodyValue.value = '';
      Object.keys(showDetails).forEach(key => {
        delete showDetails[key]; // This effectively resets each property
      });
    }


    const spinner = ref(false);



    const loadPaymentsByDate = async (date) => {
      notConfirmedExists.value = false;
      currentDate.value = date;

      spinner.value = true;

      resetChangeOrderStatusesModal()

      try {
        const resp = await store.dispatch('order/loadPaymentsByDate', date);
      //  console.log('response', resp);

        if (resp !== null && Object.keys(resp.successTtns).length > 0) {
          respData.active = true;
          spinner.value = false;
          fillSuccessTtns(resp)

        }
        // if some element revoked remove it from selectedTtns
      } catch (error) {
        console.log('error', error);
      }
    };

    function fillSuccessTtns(resp){


/*
      console.log('respData.successTtns', respData.successTtns);
      console.log('resp of revoke', resp);

      console.log('respData.successTtnsArray',respData.successTtnsArray);
      console.log('resp.successedOrdersIda',resp.successedOrdersIda);

      console.log('selectedTtns.value', selectedTtns.value);


      respData.successTtns = resp.successTtns;
      respData.successTtnsArray = resp.successedOrdersIda;
      selectedTtns.value = resp.successedOrdersIda;*/


      Object.entries(resp.successTtns).forEach(([storeName, newOrders]) => {
        if (!respData.successTtns[storeName]) {
          // If the store doesn't exist in `respData.successTtns`, add it
          respData.successTtns[storeName] = newOrders;
        } else {
          // If the store exists, update each order based on `orderId`
          newOrders.forEach(newOrder => {
            const index = respData.successTtns[storeName].findIndex(order => order.orderId === newOrder.orderId);
            if (index !== -1) {
              // Update the existing order
              respData.successTtns[storeName][index] = { ...respData.successTtns[storeName][index], ...newOrder };
            } else {
              // If the order does not exist, add it to the store's orders
              respData.successTtns[storeName].push(newOrder);
            }
          });
        }
      });

// Step 2: Update `respData.successTtnsArray` by adding new `orderId`s if they don't already exist
      resp.successedOrdersIda.forEach(orderId => {
        if (!respData.successTtnsArray.includes(orderId)) {
          respData.successTtnsArray.push(orderId);
        }
      });

// Step 3: Update `selectedTtns.value` in a similar way
      resp.successedOrdersIda.forEach(orderId => {
        if (!selectedTtns.value.includes(orderId)) {
          selectedTtns.value.push(orderId);
        }
      });



      watchArrayLength();
    }


    async function extractNumbers() {
    //  console.log('ttnsOrOrdersBodyValue.value', ttnsOrOrdersBodyValue.value);
      /*   313878696
      314198597
      313861090
      313714513
      313553534*/
      //  console.log('spinner active');
      spinerActive.value = true;
      let executedNumbers = [];
      if (selectedNumberType.value.type === 'ttn' && selectedDeliveryType.value.type === 'np') {
      //  console.log('join in NP')
        const regex = /\b\d{14}\b/g;
        executedNumbers = ttnsOrOrdersBodyValue.value.match(regex) || [];
      } else if (selectedNumberType.value.type === 'ttn' && selectedDeliveryType.value.type === 'up') {
     //   console.log('join in UP')
        const regex = /^\d{13}$/gm;
        executedNumbers = ttnsOrOrdersBodyValue.value.match(regex) || [];
      } else if (selectedNumberType.value.type === 'ttn' && selectedDeliveryType.value.type === 'roz') {
     //  console.log('join in rozetka delivery', ttnsOrOrdersBodyValue.value)
        const regex = /^\d{9}$/gm;
        executedNumbers = ttnsOrOrdersBodyValue.value.match(regex) || [];
      } else if (selectedNumberType.value.type === 'orderNumber'
       //   && (selectedSourceName.value.type === 'rozetka' || selectedSourceName.value.type === 'prom')

      ) {

        /*const regex = /^\d{9}$/gm;
        executedNumbers = ttnsOrOrdersBodyValue.value.match(regex) || [];*/
// Step 2: Store all values in `executedNumbers` array
         executedNumbers = ttnsOrOrdersBodyValue.value.split(/[^a-zA-Z0-9]+/).filter(Boolean);


       // executedNumbers = ttnsOrOrdersBodyValue.value;
      }

      const data = {
        orderStatusID: selectedOrderStatus.value.id,
        numberType: selectedNumberType.value.type,
        type: numbers(),
        numbers: executedNumbers,
      };

      function numbers() {
        if (selectedNumberType.value.type === 'ttn') {
          return selectedDeliveryType.value.type;
        } else {
          return selectedSourceName.value.type;
        }
      }

      console.log(data)

      if (data.numbers.length === 0) {
        alert('Ви не ввели жодного номера');
        return;
      } else {
        try {
         // console.log('join in try');
          const resp = await store.dispatch('order/changeOrderStatusByNumbers', data);
       //   console.log('resp', resp);
          if (resp !== null && (Object.keys(resp.successTtns).length > 0 || resp.errorTtns.length > 0)) {
            respData.active = true;
            respData.successTtns = resp.successTtns;
            respData.errorTtns = resp.errorTtns;
            respData.successTtnsArray = resp.successedOrdersIda;
            selectedTtns.value = resp.successedOrdersIda;
       //     console.log('respData.successTtns', respData.successTtns);
          } else {
        //    console.log('close modal');
            closeModal();
          }
        //  console.log('change statuses resp', resp);
        } catch (e) {
          console.log(e);
        }
        spinerActive.value = false;
      }

     // console.log('spinner', spinerActive.value);

    }


    const isExpanded = ref(false);
    const truncatedText = 'Тут відображаються ТТН Нової пошти та Укрпошти по яким... ';
    const fullText = 'Тут відображаються ТТН Нової пошти та Укрпошти по яким одержано статус від перевізника, що ви "отримали грошовий переказ",  вам треба звірити суму та кількість і якщо усе Ок, натиснути Підтвердити". якщо сума не правильна, треба знайти ТТН по якій ви  не одержали перезаз, зняти з нього прапорець, та натиснути "Підтвердити".';



    /*    const handleDatepickerOpen = () => {
          console.log('Datepicker opened');
        };

        const handleDatepickerClose = () => {
          console.log('Datepicker closed');
        };

        const handleMonthChange = (context) => {
          console.log('Month changed to:', context.month); // Тут передається поточний місяць
          // Ваш код для обробки зміни місяця
        };*/

    return {
      modalState,
      confirm,
      respData,
      showDetails,
      selectedNumberType,
      orderStatuses,
      selectOrderStatus,
      selectNumberType,
      numberTypes,
      selectedDeliveryType,
      selectDeliveryType,
      selectedSourceName,
      SourceNames,
      selectSourceName,
      deliveryTypes,
      ttnsOrOrdersBodyValue,
      selectedOrderStatus,
      extractNumbers,
      spinerActive,
//      amount,
      ...toRefs(respData),
      calculateTotal,
      toggleDetails,
      selectedTtns,
      //  currentOrderStatus,
      searchTerm,
      filterTtns,
      getOrderStatus,
    //  unselectedOrderIds,
      revokeOrderStatusChanging,
      paymentTypes,
      closeModal,
      currentDate,
      paymentsByDate,
      loadPaymentsByDate,
      showPaymentsByDate,
      daysWithPayments,
      spinner,
      filteredTtnsLength,
      getGlobalPaymentStatus,
      isExpanded,
      truncatedText,
      fullText,
      notConfirmedExists,

    };

  },

  components: {
    BSpinner,
    BModal,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    BDropdownItem,
    RecycleScroller,
    BFormInput,
    BInputGroup,
    BDropdown
  },
}
</script>

<style scoped>
.table-header, .table-body .row {
  #display: flex;
  #width: 100%;
}

/*.table-header .row, .table-body .row {
  border-bottom: 1px solid #dee2e6;
}*/

.table-header .col, .table-body .col {
  padding: 0.1rem 0.75rem;
  border-top: 1px solid #dee2e6;
  flex: 1;
}


.table-header {
  font-weight: bold;
}

.margin-padding-edit {
  margin-left: 1rem;
  padding: 0.1rem;
}

.table-body .row:last-child {
  border-bottom: 0;
  padding: 4px 0;
  border-radius: 5px;
}

.table-body {
  margin: 0.5rem;
}

.row.fw-600 {
  font-weight: 600;
  background-color: lightblue;
}

.mh-200 {
  min-height: 200px;
  #overflow-y: auto;
}

/* Кастомний стиль для зняття обмеження по висоті */
/* Кастомні стилі для випадаючого списку */
.position-static {
  position: static !important;
}

/* Кастомні стилі для виправлення випадаючого списку */
.dropdown-menu-portal {
  max-height: none !important;
  overflow: visible !important;
  position: absolute !important;
}

.b-dropdown-menu {
  overflow: auto !important; /* Знімає обмеження висоти для випадаючого меню */
  max-height: 300px; /* Задайте максимальну висоту за потреби */
}

.dropdown-menu-unrestricted {
  z-index: 9999 !important;
  max-height: none !important;
  overflow: visible !important;
}

ul.dropdown-menu.show.dropdown-menu-unrestricted {
  z-index: 9999;
}


</style>