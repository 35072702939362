<template>

  <BModal v-model="localModalStatus" size="lg" scrollable noCloseOnBackdrop noCloseOnEsc :title="modalTitle"
          @hide="closePackingModal" class="p-3"
          cancelTitle="Закрити"
          okDisabled
  >

    <div class="padding-xl product-block-header d-flex">
      <div class="w-15 text-center">
        <!-- Header for Image -->
        Зображ
      </div>
      <div class="w-70 text-center">
        <!-- Header for Product Details -->
        Артикуль та Назва товару
      </div>
      <div class="w-10 text-center">
        К-сть

      </div>
      <div class="w-5r">
        <!-- Header for Checkbox -->
        Взято
      </div>
    </div>

    <b-card  v-for="item in ordersForPacking"
             :key="item.product_id"
             class="product-block"
             :variant="buttonVariant(item.product_id)">
      <div class="d-flex pointing" :id="item.product_id" >
        <div class="w-15">
        <div class="img ">
          <BImg  :src="item.image_url" alt=""/>
        </div>
        </div>
        <div class="w-70">
          [{{ item.article }}] {{ item.product_name }}
        </div>
        <div class="w-10 text-center">
          {{ item.quantity }} од.
        </div>
        <div class="w-5 text-center">
        <BFormCheckbox
            size="sm"
            @click="setItemAsPacked(item.product_id)"
        >
        </BFormCheckbox>
        </div>
      </div>
    </b-card>
  </BModal>
</template>
<script>
import {
  BButton, BCard,
  BDropdown,
  BDropdownItem,
  BFormCheckbox, BFormInput, BFormInvalidFeedback,
  BFormSelect, BFormText,
  BFormTextarea,
  BInputGroup,
  BModal, BSpinner, BCollapse
} from 'bootstrap-vue-next';
import {computed, ref, watch} from "vue";
import {updatePackingStatus} from "@/axios/settignsRequests";

export default {
  name: "PackingListModal",

  props: {
    localModalStatus: Boolean,
    ordersForPacking: {
      type: Object,
      required: true,
    },
  },

  setup(props, {emit}) {
    const localModalStatus = ref(props.localModalStatus);
    const ordersForPacking = computed(() => props.ordersForPacking);
    const modalTitle = 'Загальний список товарів для упаковки';
  //  console.log('ordersForPacking', ordersForPacking.value);
    const packedProducts = ref([]);

    const closePackingModal = () => {
      emit('update:localModalStatus', false);
    }


    function setItemAsPacked(productId)  {
     // console.log('productId', productId);
      if (!isProductPacked(productId)) {
        packedProducts.value.push(productId);
      } else {
        packedProducts.value = packedProducts.value.filter((id) => id !== productId);
      }
    //  console.log('packedProducts', packedProducts.value);
    }

    const isProductPacked = (productId) => {
      return packedProducts.value.includes(productId);
    };

    const buttonVariant = (productId) => {
      return isProductPacked(productId) ? 'success' : 'outline-secondary';
    };

    /*
       fillPackedProducts()
        const buttonVariant = (productId) => {
          return isProductPacked(productId) ? 'success' : 'secondary';
        };
      let packed_ordered_products_ids = [];

        const setItemAsPacked = (item) => {
          const productId = item.product_id;
          if (!isProductPacked(productId)) {
            packedProducts.value.push(productId);
            packed_ordered_products_ids.push(item.id);
          }
          console.log('packed_ordered_products_ids', packed_ordered_products_ids);
        };

        const isProductPacked = (productId) => {
          return packedProducts.value.includes(productId);
        };

    function fillPackedProducts() {
      order.value.products.forEach((item) => {
        if (item.is_packed) {
          packedProducts.value.push(item.product_id);
        }
      });
    }*/

    function saveChanges() {

    }


    return {
      modalTitle,
      localModalStatus,
      ordersForPacking,
      closePackingModal,
      setItemAsPacked,
      saveChanges,
      buttonVariant
    }
  },

  components: {
    BModal,
    BFormTextarea,
    BFormCheckbox,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BInputGroup,
    BCard,
    BFormInput,
    BSpinner,
    BFormText,
    BFormInvalidFeedback,
    BCollapse
  },

}


</script>

<style scoped>


@media (min-width: 768px) {
  /* Desktops and larger devices */
  .img img {
    max-width: 300px;
  }
}

.img img {
  width: 90%;
  height: auto; /* This maintains the aspect ratio */
}


@media (min-width: 768px) { /* Desktops and larger devices */
  .desktop-packing-details {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}




</style>