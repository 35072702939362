<template>
  <div class="container-fluid">
    <div class="table-bordered margin-padding-edit mt-3">
      <BInputGroup size="sm" prepend="Пошук">
        <BFormInput
            v-model="searchTerm"
            placeholder="пошук по номеру"
        />
      </BInputGroup>
      <div class="mt-3">
      <div class="table-body"
           v-for="(ttns, contactPerson) in dropReport"
           :key="contactPerson">
        <div class="row ">
          <div class="col-5">
            <BButton variant="outline-secondary" size="sm"
                     @click="toggleDetails(contactPerson)">Див
            </BButton>
            {{ ttns[0].buyer.name }}
          </div>
          <div class="col-3">{{ selectedTtns[contactPerson].length }} шт.</div>
          <div class="col-2"> <b> {{ dropAmountSummary[contactPerson] }} грн </b></div>
          <div class="col-2">
            <BButton variant="outline-primary" size="sm"
                     @click="saveAsPaid(contactPerson)">Відмітити як сплачені
            </BButton>
          </div>
        </div>
        <template v-if="showDetails[contactPerson]" class="details">
          <RecycleScroller
              class="div-table-body scroller overflow-y-scroll"
              :items="filterTtns(ttns)"
              :key-field="'id'"
              page-mode
              :item-size="30"
          >
            <template #before>
              <div class="div-table-row white-space-nowrap border-top">
                <div class="selectOrderWidth div-table-cell"></div>
                <div class="MarketplacePaymentTypeWidth div-table-cell">Тип оплати</div>
                <div class="DeliveryTypeWidth div-table-cell">Статус замов</div>
                <div class="total_amountWidth div-table-cell">Взаєморозрахунок</div>
                <div class="total_amountWidth div-table-cell">Сумма зам-ня</div>
                <div class="total_amountWidth div-table-cell">Предоп</div>
                <div class="total_amountWidth div-table-cell">Сумма дро-ра</div>
                <div class="total_amountWidth div-table-cell">Вартість доставки</div>
                <div class="DeliveryTypeWidth div-table-cell">Платник</div>

                <div class="TtnNumberWidth div-table-cell">ТТН</div>
                <div class="DeliveryStatusWidth div-table-cell">Статус доставки</div>
                <div class="BackTtnStatusWidth div-table-cell">Статус звор доставки</div>
                <div class="BackTtnStatusWidth div-table-cell">Комментар</div>
              </div>
            </template>
            <template v-slot="{ item }">
              <div class="div-table-row" :key="item.id">
                <div class="selectOrderWidth div-table-cell">
                  <BFormCheckbox
                      v-model="selectedTtns[contactPerson]"
                      :key="item.id"
                      :value="item.id">
                  </BFormCheckbox>
                </div>
                <div class="MarketplacePaymentTypeWidth div-table-cell">
                  {{ paymentTypes[item.backward_delivery_cargo_types_id].name }}
                </div>
                <div class="DeliveryTypeWidth div-table-cell"> {{ orderStatuses[item.order_status_id].name }}</div>
<!--                <div class="total_amountWidth div-table-cell" > <b> {{ item.drop_amount }} </b></BFormInput>-->
<!--                <div>
                  <div
                      class="total_amountWidth div-table-cell"
                      @dblclick="editField(index)"
                      v-if="!editIndex[index]"
                  >
                    <b>{{ item.drop_amount }}</b>
                  </div>
                  <div class="total_amountWidth div-table-cell" v-else>
                    <BFormInput
                        type="number"
                        step="0.01"
                        v-model="item.drop_amount"
                        @blur="saveEdit(index)"
                        @keyup.enter="saveEdit(index)"
                    />
                  </div>
                </div>-->
                <BFormInput size="sm" class="total_amountWidth div-table-cell" v-model="item.drop_amount"/>
                <div class="total_amountWidth div-table-cell"> {{ item.total_amount }}</div>
                <div class="total_amountWidth div-table-cell"> {{ item.pre_paid_amount }}</div>
                <div class="total_amountWidth div-table-cell"> {{ item.drop_price }}</div>
                <div class="total_amountWidth div-table-cell">
                  {{ item.delivery_info?.order_np_ttn_back_info?.cost_delivery }}
                </div>
                <div class="DeliveryTypeWidth div-table-cell">
                  {{ item.delivery_info?.backward_del_payer === 4 ? 'Одержувач' : 'Відправник' }}
                </div>

                <div class="TtnNumberWidth div-table-cell">{{
                    item.delivery_info?.order_np_ttn_back_info?.number_ttn
                  }}
                </div>
                <div class="DeliveryStatusWidth div-table-cell">
                  {{ getDeliveryStatusNameById(item.delivery_info?.order_np_ttn_back_info?.delivery_status) }}
                </div>
                <div class="BackTtnStatusWidth div-table-cell">
                  {{ getDeliveryStatusNameById(item.delivery_info?.order_np_ttn_back_info?.back_delivery_status) }}
                </div>
                <div class="DeliveryStatusWidth div-table-cell"> {{ item.order_comment }}</div>
              </div>
            </template>
          </RecycleScroller>
        </template>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BModal, BFormInput} from 'bootstrap-vue-next'
import {computed, onMounted, reactive, ref} from "vue";
import {getDeliveryStatuses, getDropReportData, savePaidDropOrders} from "@/axios/settignsRequests";
import {RecycleScroller} from 'vue-virtual-scroller'
import {useStore} from "vuex";

export default {
  name: "dropReport",
  setup() {
    const store = useStore();
    const dropReport = ref()
    const npDeliveryStatuses = reactive({})
    const orderStatuses = ref({})
    const paymentTypes = ref({})
    const selectedTtns = ref([]);
    onMounted(async () => {
      orderStatuses.value = store.getters['order/getOrderStatuses'];
     // console.log('orderStatuses', orderStatuses.value)

      paymentTypes.value = store.getters['order/getPaymentTypes'];
    //  console.log('paymentTypes', paymentTypes)

      const response = await getDeliveryStatuses()
      npDeliveryStatuses.value = response.np_statuses
     // console.log('deliveryStatuses', npDeliveryStatuses.value)
      dropReport.value = await getDropReportData()
      // fill selectedOrders by dropReport => contact person => datas => data => id
      Object.keys(dropReport.value).forEach((contactPersonId) => {
        // Ensure there's an array to push to for each contactPersonId
        if (!selectedTtns.value[contactPersonId]) {
          selectedTtns.value[contactPersonId] = [];
        }

        dropReport.value[contactPersonId].forEach((order) => {
         // console.log('item', order);
          selectedTtns.value[contactPersonId].push(order.id); // Now it's safe to push
        });
      });

    })
    // calculate drop_amount summary using computed and selected ttns ids by contact person
    const dropAmountSummary = computed(() => {
      return Object.keys(selectedTtns.value).reduce((acc, contactPersonId) => {
        const selectedTtnsIds = selectedTtns.value[contactPersonId];
        const val = dropReport.value[contactPersonId].filter(ttn => selectedTtnsIds.includes(ttn.id));
        acc[contactPersonId] = val.reduce((sum, ttn) => sum + parseFloat(ttn.drop_amount), 0);  // Store the total for each contactPersonId in the accumulator object
        return acc;
      }, {});
    });
   // console.log('dropReport', dropReport.value)
    const searchTerm = ref('');
    const filterTtns = (ttns) => {
    //  console.log('ttns');
    //  console.log('ttns', ttns);
    //  console.log('searchTerm', searchTerm.value); // 8

      if (!searchTerm.value) {
     //   console.log('!searchTerm.value', searchTerm.value);
        return ttns;
      }
      // Convert both ttn.ttns and searchTerm.value to string before comparison
      const res = ttns.filter(ttn =>
          ttn.delivery_info.order_np_ttn_back_info.number_ttn.toString().includes(searchTerm.value.toString()));

   // console.log('res', res);
      return res;
    };
    const showDetails = reactive({});
    const toggleDetails = (contactPerson) => {
    //  console.log('contactPerson', contactPerson);
      showDetails[contactPerson] = !showDetails[contactPerson];
    };
    const getDeliveryStatusNameById = (id) => {
     // console.log(id)
      if (!id) return ''
      return npDeliveryStatuses.value[id]?.name
    }
    let dataForSaveByContactPerson = [ ];

/*
    async function saveAsPaid(contactPersonId) {
      // Retrieve selected TTN IDs for the contact person
      const selectedTtnsIds = selectedTtns.value[contactPersonId];

      // Get orders from dropReport.value for the specified contactPersonId
      const orders = dropReport.value[contactPersonId];

      // Filter orders to include only those whose IDs are in selectedTtnsIds
      const filteredOrders = orders.filter(order => selectedTtnsIds.includes(order.id));

      console.log('filteredOrders', filteredOrders);


      // Loop through the filtered orders to populate dataForSaveByContactPerson
      filteredOrders.forEach(order => {
        dataForSaveByContactPerson.push({
          order_id: order.id,
          buyer_id: order.buyer_id,
          user_id: order.user_id,
          amount: order.drop_amount,  // Assuming 'drop_amount' is the correct field for the amount
        });
      });


      console.log('dataForSaveByContactPerson.length', dataForSaveByContactPerson.length);
      console.log('dataForSaveByContactPerson', dataForSaveByContactPerson);

      const res = await savePaidDropOrders({data: dataForSaveByContactPerson})
      if (res) {
        console.log('res', res);
        await store.dispatch('alert/triggerAlert', {
          message: res.message,
          variant: res.status
        });

        // update dropReport after save


      }


      dataForSaveByContactPerson = [];

    }*/




    async function saveAsPaid(contactPersonId) {
      // Retrieve selected TTN IDs for the contact person
      const selectedTtnsIds = selectedTtns.value[contactPersonId];

      // Get orders from dropReport.value for the specified contactPersonId
      const orders = dropReport.value[contactPersonId];

      // Filter orders to include only those whose IDs are in selectedTtnsIds
      const filteredOrders = orders.filter(order => selectedTtnsIds.includes(order.id));

      // Prepare data for CSV
      let csvContent = "data:text/csv;charset=utf-8," +
          "ТТН,Одержувач,Телефон,Спосіб оплати,Статус замовлення,Ціна одержувача,Вартість доставки,Платник доставки,Предоплата,Сумма замовлення,Взаєморозрахунок,Статус доставки,Статус зворот. доставки,Комментар\n";

      filteredOrders.forEach(order => {
        dataForSaveByContactPerson.push({
          order_id: order.id,
          buyer_id: order.buyer_id,
          user_id: order.user_id,
          amount: order.drop_amount,  // Assuming 'drop_amount' is the correct field for the amount
        });

        // Create a line for CSV

        // ttn value to string
        let ttn = order.delivery_info?.order_np_ttn_back_info?.number_ttn ?? '';
        ttn = "'" + String(ttn) + "'";
        csvContent += [
          ttn,
          order.buyer.name,
          order.buyer.phone,
          paymentTypes.value[order.backward_delivery_cargo_types_id].name,
          orderStatuses.value[order.order_status_id].name,
          order.drop_price,
          order.delivery_info?.order_np_ttn_back_info?.cost_delivery || 0,
          order.delivery_info?.backward_del_payer === 4 ? 'Одержувач' : 'Відправник',
          order.pre_paid_amount,
          order.total_amount,
          order.drop_amount,
          getDeliveryStatusNameById(order.delivery_info?.order_np_ttn_back_info?.delivery_status) ?? "",
          getDeliveryStatusNameById(order.delivery_info?.order_np_ttn_back_info?.back_delivery_status) ?? "",
          order.order_comment || ""
        ].join(",") + "\n";
      });

      // Create a downloadable link for the CSV
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `orders_for_${contactPersonId}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Save data using your existing logic
      const res = await savePaidDropOrders({data: dataForSaveByContactPerson});
      if (res) {
        console.log('res', res);
        await store.dispatch('alert/triggerAlert', {
          message: res.message,
          variant: res.status
        });

        // update dropReport after save
      }

      // Clear the array after save
      dataForSaveByContactPerson = [];
    }







    return {
      dropReport,
      filterTtns,
      searchTerm,
      saveAsPaid,
      toggleDetails,
      showDetails,
      paymentTypes,
      selectedTtns,
      npDeliveryStatuses, getDeliveryStatusNameById,
      orderStatuses,
      dropAmountSummary

    }
  },

  components: {
    BModal,
    RecycleScroller,
    BFormInput
  }

}
</script>

<style scoped>

</style>